import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeTC from '../views/HomeTC.vue'
import test from '../views/About.vue'
import gen from '../views/gen.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tc',
    name: 'Trimble Connect',
    component: HomeTC
  },
  {
    path: '/test',
    name: 'test',
    component: test
  },
  {
    path: '/gen',
    name: 'Gen',
    component: gen
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
