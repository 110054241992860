<template>
  <!-- <div class="home">
    <h1>Construsoft</h1>
    <b-field type="is-centered">
    <b-input type="text" placeholder="Společnost" v-model="company"></b-input>
    <b-button type="is-primary" @click="sendEmail()">Send email</b-button>
    </b-field>
  </div> -->
<section class="section">
    <div class="container" style="padding: 20px; box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; margin-top: 5%;border-radius: .5rem;">
      <b-loading v-model="loading" is-full-page></b-loading>

      <img src="https://ik.imagekit.io/construsoft/tc/Logo_UniQ05liy.svg?updatedAt=1680764720067" alt="Trimble Connect logo" style="margin: 0 auto; display: block; width: 33%;" class="mb-4"/>
      <!-- <img src="https://www.construsoft.com/themes/construsoft_gavias/logo.png" style="margin: 0 auto; display: block; width: 20%;" class="mb-4"/> -->


      <b-field label="Země">
        <b-select expanded placeholder="Vyberte zemi" @input="results=false; ičo=null; feedbackIČO=null; feedback=null" v-model="selectedCountry">
          <option value="cz">Česko</option>
          <option value="sk">Slovensko</option>
        </b-select>
      </b-field>

      <b-field v-if="selectedCountry === 'cz'" label="IČO (CZ)" :type="feedbackIČO ? 'is-danger' : ''" :message="feedbackIČO">
        <b-input :disabled="loading" v-model="ičo" @input="getData()"></b-input>
      </b-field>

      <b-field v-if="selectedCountry === 'sk'" label="IČO (SK)" :type="feedbackIČO ? 'is-danger' : ''" :message="feedbackIČO">
        <b-input :disabled="loading" v-model="ičo" @input="getDataSK()"></b-input>
      </b-field>

      <div v-if="results || selectedCountry === 'sk'" class="mb-4">
        <div><div class="divider">Sídlo společnosti</div></div>

        <b-field label="Společnost"><b-input name="organization" :disabled="loading" v-model="společnost"></b-input></b-field>
        <b-field grouped>
          <b-field expanded label="Ulice a č.p."><b-input name="street-address" :disabled="loading" v-model="ulice"></b-input></b-field>
          <b-field expanded label="Město"><b-input name="address-level2" :disabled="loading" v-model="město"></b-input></b-field>
          <b-field expanded label="PSČ"><b-input name="postal-code" :disabled="loading" v-model="psč"></b-input></b-field>
          <b-field expanded label="Stát"><b-input name="country" :disabled="loading" v-model="stát"></b-input></b-field>
        </b-field>

        <div><div class="divider">Korespondenční adresa</div></div>

        <b-field grouped>
          <b-field expanded><b-checkbox v-model="adresaSeLiší">Liší se korespondenční adresa od sídla společnosti?</b-checkbox></b-field>
        </b-field>

        <b-field grouped v-if="adresaSeLiší">
          <b-field expanded label="Ulice a č.p."><b-input :disabled="loading" v-model="ulice_kor"></b-input></b-field>
          <b-field expanded label="Město"><b-input :disabled="loading" v-model="město_kor"></b-input></b-field>
          <b-field expanded label="PSČ"><b-input :disabled="loading" v-model="psč_kor"></b-input></b-field>
          <b-field expanded label="Stát"><b-input :disabled="loading" v-model="stát_kor"></b-input></b-field>
        </b-field>

        <div><div class="divider"><i class="fas fa-info-circle mr-2" v-tooltip="desc['zástupce']"></i> Osoby oprávněné zastupovat firmu</div></div>
        <b-field grouped v-for="n in početOsob" :key="`z${n}`" :label="`Zástupce ${n}`" class="flex-end">
          <b-field expanded label="Jméno"><b-input name="first-name" :disabled="loading" v-model="zástupci[n-1].jméno"></b-input></b-field>
          <b-field expanded label="Příjmení"><b-input name="last-name" :disabled="loading" v-model="zástupci[n-1].příjmení"></b-input></b-field>
          <b-field expanded label="Pozice"><b-input name="organization-title" :disabled="loading" v-model="zástupci[n-1].pozice"></b-input></b-field>
          <b-field><b-button type="is-danger" icon-left="delete" outlined :disabled="n <= 1" @click="removeUser(n-1)"></b-button></b-field>
        </b-field>
        <b-button @click="addUser()" icon-left="plus" type="is-success" class="mr-2" outlined>Přidat zástupce</b-button>

        <div><div class="divider"><i class="fas fa-info-circle mr-2" v-tooltip="desc['admin']"></i> Osoba odpovědná za administraci licencí</div></div>
        <b-field grouped>
          <b-field expanded label="Jméno"><b-input :disabled="loading" v-model="admin_jméno"></b-input></b-field>
          <b-field expanded label="Příjmení"><b-input :disabled="loading" v-model="admin_příjmení"></b-input></b-field>
          <b-field expanded label="Email"><b-input :disabled="loading" v-model="admin_email"></b-input></b-field>
          <b-field expanded label="Telefon"><b-input :disabled="loading" v-model="admin_telefon"></b-input></b-field>
        </b-field>

        <div><div class="divider">Licence</div></div>
        <b-field label="Typ licence">
            <b-select expanded placeholder="Vyberte..." v-model="selectedLicence">
              <option v-for="(option, index) in licence" :value="option" :key="index">{{ option }}</option>
            </b-select>
        </b-field>
        <!-- licence: ["Trimble Connect Business (Business Premium)", "Trimble Connect Project- licence na projekt", "Trimble Connect Enterprise - firemní licence"], -->

        <div v-if="selectedLicence == 'Trimble Connect Business / Business Premium'">
          <b-field grouped>
            <b-field label="Počet kusů Business licencí" expanded>
              <b-numberinput v-model="početKusůB" step=5 min=0 exponential></b-numberinput>
            </b-field>
            <b-field label="Počet kusů Business Premium licencí" expanded>
              <b-numberinput v-model="početKusůBP" step=1 min=0 :exponential="0.5"></b-numberinput>
            </b-field>
          </b-field>
        </div>

        <div v-if="selectedLicence == 'Trimble Connect Project - licence na projekt'">
          <b-field grouped>
            <b-field expanded><template #label><i class="fas fa-info-circle mr-2" v-tooltip="desc['názevProjektu']"></i>Název projektu</template>
              <b-input v-model="názevProjektu"></b-input>
            </b-field>
            <b-field expanded><template #label><i class="fas fa-info-circle mr-2" v-tooltip="desc['rozpočet']"></i>Rozpočtové náklady projektu v EUR</template>
              <b-input icon="currency-eur" v-model="rozpočet" type="number"></b-input>
            </b-field>
          </b-field>
        </div>

        <div v-if="selectedLicence == 'Trimble Connect Enterprise - firemní licence'">
          <b-field grouped>
            <b-field expanded><template #label><i class="fas fa-info-circle mr-2" v-tooltip="desc['email']"></i>E-mailová doména</template>
              <b-input icon="at" v-model="emailDoména"></b-input>
            </b-field>
            <b-field expanded><template #label><i class="fas fa-info-circle mr-2" v-tooltip="desc['obrat']"></i>Obrat společnosti v EUR</template>
              <b-input icon="currency-eur" v-model="obrat" type="number"></b-input>
            </b-field>
          </b-field>
        </div>


        <div><div class="divider">Faktura a smlouva</div></div>
        <b-field grouped label="Měna platby">
          <b-select placeholder="Vyberte měnu..." v-model="měnaPlatby" expanded>
              <option value="CZK">CZK</option>
              <option value="EUR">EUR</option>
          </b-select>
        </b-field>
        <b-field grouped label="Způsob zaslání faktury a smlouvy">
          <b-select placeholder="Vyberte způsob..." v-model="způsobZaslání" expanded>
              <option value="Elektronicky">Elektronicky</option>
              <option value="Poštou">Poštou</option>
          </b-select>
        </b-field>

        <b-field grouped v-if="způsobZaslání == 'Elektronicky'">
          <b-field expanded label="E-mailová adresa pro faktury"><b-input :disabled="loading" v-model="email_faktura"></b-input></b-field>
          <b-field expanded label="E-mailová adresa pro smlouvy"><b-input :disabled="loading" v-model="email_smlouva"></b-input></b-field>
        </b-field>

        <div v-if="!selectedSegment || !selectedMaterial || !selectedNovyZakaznik"><div class="divider">Ostatní</div></div>
        <b-field grouped>
          <b-field expanded label="Segment" v-if="!selectedSegment">
            <b-select expanded :disabled="$route.query.segment" placeholder="Vyberte..." v-model="selectedSegment">
              <option v-for="(option, index) in segment" :value="option" :key="index">{{ option }}</option>
            </b-select>
          </b-field>

          <b-field expanded label="Main material" v-if="!selectedMaterial">
            <b-select expanded :disabled="$route.query.material" placeholder="Vyberte..." v-model="selectedMaterial">
              <option v-for="(option, index) in material" :value="option" :key="index">{{ option }}</option>
            </b-select>
          </b-field>

          <b-field expanded label="Nový zákazník?" v-if="!selectedNovyZakaznik">
            <b-select expanded :disabled="$route.query.zakaznik" placeholder="Vyberte..." v-model="selectedNovyZakaznik">
              <option v-for="(option, index) in novyZakaznik" :value="option" :key="index">{{ option }}</option>
            </b-select>
          </b-field>
        </b-field>
        <hr/>

        <b-field position="is-centered" >
          <b-button expanded type="is-primary" @click="sendEmail()">ODESLAT</b-button>
          <!-- <b-button expanded type="is-primary" @click="test()">TEST</b-button> -->
        </b-field>
      </div>

      <b-field v-if="feedback">
        <p>{{feedback}}</p>
      </b-field>
    </div>
    <!-- <p v-html="text"></p><a href="#" @click.prevent="test()">Update text</a> -->
  </section>
</template>

<script>
import { functions } from '../firebase'


export default {
  name: 'Home',
  data: function() {
    return {
      loading: false,
      feedback: null,
      feedbackIČO: null,
      results: false,
      data: null,
      suggest: null,
      text: "asd",
      selectedCountry: null,

      ičo: null,
      společnost: null,
      město: null,
      ulice: null,
      psč: null,
      stát: null,

      admin_jméno: null,
      admin_příjmení: null,
      admin_email: null,
      admin_telefon: null,

      zástupci: [{ jméno: null, příjmení: null, pozice: null }],
      početOsob: 1,
      maxPočetUživatelů: 10,

      město_kor: null,
      ulice_kor: null,
      psč_kor: null,
      stát_kor: null,

      adresaSeLiší: false,

      početKusůB: 0, početKusůBP: 0,

      názevProjektu: null,
      rozpočet: null,

      emailDoména: null,
      obrat: null,

      měnaPlatby: null,
      způsobZaslání: null,
      email_faktura: null,
      email_smlouva: null,

      formURL: null,

      selectedSegment: 'NA', selectedMaterial: 'NA', selectedNovyZakaznik: 'NA', selectedLicence: 'NA',
      segment: ["Engineering", "Steel", "Precast", "Cast in place", "Contractors", "Educational"],
      material: ["Steel", "Cast in place", "Precast", "Rebar", "Timber", "Aluminium", "Temporary structures", "MEP", "Other"],
      novyZakaznik: ["ano", "ne"],
      licence: ["Trimble Connect Business / Business Premium", "Trimble Connect Project - licence na projekt", "Trimble Connect Enterprise - firemní licence"],

      desc: {
        "zástupce": "Osoba, která bude uvedena na objednávce (smlouvě) a je oprávněna smlouvu podepsat. ",
        "admin": "Této osobě budou zaslány informace o vývoji objednávky a termínu aktivace licence. Zároveň bude tato osoba ustanovena jako administrátor společnosti v systémech pro správu licencí (Account Manager) a zodpovídá za přidělení jednotlivých licencí. Správce může být následně měněn.",
        "uživatelé": "Osoba, která bude používat program Tekla Structures. E-mailová adresa nemusí<br>souhlasit s emailovou adresu Trimble ID pro spuštění programu Tekla.",
        "názevProjektu": "Uveďte přesný název projektu, na který bude projektová licence nasazena.",
        "rozpočet": "Uveďte předpokládané známé rozpočtové náklady projektu.",
        "email": "Uveďte prosím Vaši firemní e-mailovou doménu. Uživatelé s touto doménou budou mít možnost neomezeného přístupu k licencím - např. @construsoft.com",
        "obrat" : "Uveďte prosím poslední známý obrat Vaší společnosti."
      }
    }
  },
  computed:{
    users(){
      return this.početUživatelů
    }
  },
  mounted() {
    const query = this.$route.query;

    this.selectedMaterial = this.material[parseInt(query.material) - 1] || query.ma || '';
    this.selectedSegment = this.segment[parseInt(query.segment) - 1] || query.se || '';
    this.selectedNovyZakaznik = this.novyZakaznik[parseInt(query.zakaznik) - 1] || query.nz || '';
    this.selectedLicence = this.licence[parseInt(query.licence) - 1] || query.li || '';

    this.ičo = query.ičo;

    if (query.st === 'sk') {
      this.selectedCountry = 'sk';
      this.getDataSK();
    } else if (query.st === 'cz') {
      this.selectedCountry = 'cz';
      this.getData();
    }

    this.admin_jméno = query.aj || '';
    this.admin_příjmení = query.ap || '';
    this.admin_email = query.ae || '';
    this.admin_telefon = query.at || '';

    this.měnaPlatby = query.mp || '';
    this.způsobZaslání = query.zz || '';
    this.email_faktura = query.ef || '';
    this.email_smlouva = query.es || '';

    this.adresaSeLiší = query.al === 'true';
    this.město_kor = query.mk || '';
    this.ulice_kor = query.uk || '';
    this.psč_kor = query.pk || '';
    this.stát_kor = query.sk || '';

    this.početKusůB = query.kb || '';
    this.početKusůBP = query.kp || '';
    this.názevProjektu = query.np || '';
    this.rozpočet = query.rn || '';
    this.emailDoména = query.ed || '';
    this.obrat = query.os || '';

    if (query.z) {
      const users = JSON.parse(decodeURIComponent(query.z))
      this.početOsob = users.length;
      this.zástupci = []

      users.forEach(user => {
        const [jméno, příjmení, pozice] = user.split(';');
        this.zástupci.push({
          jméno: jméno || '',
          příjmení: příjmení || '',
          pozice: pozice || '',
        })
      });
    }
  },
  methods: {
    test(){
      this.setText()
      this.setUrl()
      console.log({ text: this.text, url: this.formURL })
    },
    addUser() {
      if (this.početOsob < this.maxPočetUživatelů) {
        this.zástupci.push({ jméno: null, příjmení: null, pozice: null })
        this.početOsob++
      } else {
        this.toast("Maximální počet osob je "+this.maxPočetUživatelů, "warning")
      }
    },
    removeUser(n) {
      this.zástupci.splice(n, 1)
      this.početOsob--
    },
    setUrl() {
      const params = {
        'ičo': this.ičo,
        'st': this.selectedCountry,
        'aj': this.admin_jméno,
        'ap': this.admin_příjmení,
        'ae': this.admin_email,
        'at': this.admin_telefon,
        'mp': this.měnaPlatby,
        'zz': this.způsobZaslání,
        'al': this.adresaSeLiší.toString(),
        'ma': this.selectedMaterial,
        'se': this.selectedSegment,
        'pu': this.početUživatelů,
        'nz': this.selectedNovyZakaznik,
        'li': this.selectedLicence,
        'z': []
      };

      for (let i = 0; i < this.zástupci.length && i < this.početOsob; i++) {
        const zástupce = this.zástupci[i];
        if (!zástupce.jméno && !zástupce.příjmení && !zástupce.pozice) {
          continue;
        }
        params['z'].push(`${zástupce.jméno};${zástupce.příjmení};${zástupce.pozice}`);
      }

      params['z'] = encodeURIComponent(JSON.stringify(params['z']))

      if (this.způsobZaslání === "Elektronicky") {
        params['ef'] = this.email_faktura;
        params['es'] = this.email_smlouva;
      }

      if (this.adresaSeLiší) {
        params['mk'] = this.město_kor;
        params['uk'] = this.ulice_kor;
        params['pk'] = this.psč_kor;
        params['sk'] = this.stát_kor;
      }

      switch (this.selectedLicence) {
        case 'Trimble Connect Business / Business Premium':
          params['kb'] = this.početKusůB;
          params['kp'] = this.početKusůBP;
          break;

        case 'Trimble Connect Project - licence na projekt':
          params['np'] = this.názevProjektu;
          params['rn'] = this.rozpočet;
          break;

        case 'Trimble Connect Enterprise - firemní licence':
          params['ed'] = this.emailDoména;
          params['os'] = this.obrat;
          break;

        default:
          break;
      }

      const queryString = Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      const url = `${window.location.origin}/tc?${queryString}`;
      this.formURL = url;
    },
    setText(){
      this.text = `
      <b>Společnost</b><br>${this.společnost}<br><br>
      <b>IČO</b><br>${this.ičo}<br><br>
      <b>Nový zákazník</b><br>${this.selectedNovyZakaznik}<br><br>
      <b>Sídlo společnosti</b><br>${this.ulice}, ${this.město}, ${this.psč}  ${this.stát}<br><br>
      `
      if(this.adresaSeLiší == false){ this.text = this.text +  `<b>Korespondenční adresa</b><br>Stejná jako sídlo společnosti<br><br>` }
      else{ this.text = this.text +  `<b>Korespondenční adresa</b><br>${this.ulice_kor}, ${this.město_kor}, ${this.psč_kor}  ${this.stát_kor}<br><br>` }

      const zástupci = this.zástupci.map(z => {
        return `${z.jméno} ${z.příjmení}, ${z.pozice}`
      }).join('<br>')

      this.text = this.text + `
      <b>Osoby oprávněné zastupovat firmu (${this.početOsob})</b><br>${zástupci}<br><br>
      <b>Osoba odpovědná za administraci licencí</b><br>${this.admin_jméno} ${this.admin_příjmení}, ${this.admin_email}, ${this.admin_telefon}<br><br>
      <b>Typ licence</b><br>${this.selectedLicence}<br><br>
      `

      switch (this.selectedLicence) {
        case 'Trimble Connect Business / Business Premium':
          this.text = this.text + `<b>Počet licencí Business</b><br>${this.početKusůB}<br><br><b>Počet licencí Business Premium</b><br>${this.početKusůBP}<br><br>`
          break

        case 'Trimble Connect Project - licence na projekt':
          this.text = this.text + `<b>Název projektu</b><br>${this.názevProjektu}<br><br><b>Rozpočtové náklady projektu</b><br>${this.rozpočet}€<br><br>`
          break

        case 'Trimble Connect Enterprise - firemní licence':
          this.text = this.text + `<b>E-mailová doména</b><br>@${this.emailDoména}<br><br><b>Obrat společnosti</b><br>${this.obrat}€<br><br>`
          break

        default:
          break
      }

      this.text = this.text + `
      <b>Měna platby</b><br>${this.měnaPlatby}<br><br>
      <b>Způsob zaslání faktury a smlouvy</b><br>${this.způsobZaslání}<br><br>
      `

      if(this.způsobZaslání == "Elektronicky"){
        this.text = this.text +  `<b>Emailová adresa pro faktury</b><br>${this.email_faktura}<br><br>
        <b>Emailová adresa pro smlouvy</b><br>${this.email_smlouva}<br><br>`
      }

      this.text = this.text + `
      <b>Segment</b><br>${this.selectedSegment}<br><br>
      <b>Main material</b><br>${this.selectedMaterial}
      `
    },
    toast(text, type){
      this.$buefy.toast.open({ message: text, type: 'is-'+type})
    },
    async getDataSK() {
      this.stát = 'Slovensko'
      // if (!this.ičo || this.ičo.length !== 8 || !/^\d+$/.test(this.ičo)) {
      //   this.results = false;
      //   this.loading = false;
      //   this.feedbackIČO = "Překontrolujte prosím své IČO";
      //   return;
      // }
      //
      // this.loading = true;
      //
      // try {
      //   const response = await fetch(`https://autoform.ekosystem.slovensko.digital/api/corporate_bodies/search?q=cin:${this.ičo}&private_access_token=6581432872ef50088acc254f60a609812f752bd14f95e0eed1cfb852a4ca540d0d0f490ae8f11fc2`);
      //   const data = await response.json();
      //
      //   this.results = true;
      //   this.společnost = data[0].name;
      //   this.ulice = data[0].formatted_street;
      //   this.město = data[0].municipality;
      //   this.psč = data[0].postal_code;
      //   this.stát = "Slovensko";
      //   this.loading = false;
      //   this.feedbackIČO = null;
      //   this.feedback = null;
      // } catch (error) {
      //   this.results = false;
      //   this.loading = false;
      //   this.feedbackIČO = "Překontrolujte prosím své IČO";
      // }
    },
    async getData() {
      if (!this.ičo || this.ičo.length !== 8 || !/^\d+$/.test(this.ičo)) {
        this.results = false;
        this.loading = false;
        this.feedbackIČO = "Překontrolujte prosím své IČO";
        return;
      }

      this.loading = true;

      try {
        const response = await fetch(`https://ares.gov.cz/ekonomicke-subjekty-v-be/rest/ekonomicke-subjekty/${this.ičo}`, {
          headers: {
            'x-requested-With': 'XMLHttpRequest',
            'Accept': 'application/json'
          }
        });

        if (!response.ok) {
          console.log('Status not 200');
          this.results = false;
          this.loading = false;
          this.společnost = null;
          this.město = null;
          this.ulice = null;
          this.číslo_popisné = null;
          this.feedbackIČO = 'Nepodařilo se načíst data. Zkuste to, prosím, později.'
          return;
        }

        const res = await response.json()

        this.results = true;
        this.společnost = res.obchodniJmeno;
        this.ulice = `${res.sidlo.nazevUlice} ${res.sidlo.cisloDomovni}/${res.sidlo.cisloOrientacni}`;
        this.město = res.sidlo.nazevObce;
        this.psč = res.sidlo.psc;
        this.stát = res.sidlo.nazevStatu;
        this.loading = false;
        this.feedback = null;
        this.feedbackIČO = null;
      } catch (e) {
        this.results = false;
        this.loading = false;
        this.společnost = null;
        this.město = null;
        this.ulice = null;
        this.číslo_popisné = null;
        this.feedbackIČO = `Překontrolujte prosím své IČO. ${e}`;
      }
    },
    getNodeValue(dom, tag, co) {
      const element = dom.getElementsByTagName(tag)[0];
      if (element) {
        const nodeValue = element.childNodes[0].nodeValue;
        return co ? `/${nodeValue}` : nodeValue;
      } else {
        return '';
      }
    },
    checkReq(){
      if(this.společnost && this.ulice && this.město && this.psč && this.stát && this.zástupci[0].jméno && this.admin_jméno && this.admin_příjmení && this.admin_email && this.admin_telefon && this.měnaPlatby && this.způsobZaslání && this.selectedSegment && this.selectedMaterial){
        if(this.způsobZaslání == "Elektronicky"){
          if(!this.email_faktura || !this.email_smlouva){ console.log("e2"); return false }
        }

        if(this.adresaSeLiší == true){
          if(!this.ulice_kor || !this.město_kor || !this.psč_kor || !this.stát_kor){ console.log("e3"); return false }
        }

        return true
      }else{
        console.log("e1")
        return false
      }
    },
    async sendEmail(){
      if(this.checkReq()){
        this.loading = true
        this.setText()
        this.setUrl()

        const sendTo = ["dan@srbec.ky", "martin.matasovsky@construsoft.com", "licence@construsoft.cz", "support-cz@construsoft.com", "pavel.kochta@construsoft.com"]

        const callable = functions.httpsCallable('genericEmail')
        const data = await callable({
          to: sendTo,
          subject: `Zákazník ${this.společnost} vyplnil formulář (Trimble Connect)`,
          company: this.společnost,
          text: this.text,
          formURL: this.formURL
        })

        this.$buefy.toast.open({ message: 'Povedlo se!', type: 'is-success'})
        Object.assign(this.$data, this.$options.data.apply(this))

        console.log(data)
        this.loading = false
      }else{
        this.feedback = "Zkontrolujte prosím všechny údaje."
      }
    }
  }
}
</script>

<style>
.fa-info-circle:hover{
  color: #00A1E5;
}
.fa-info-circle{
  transition: .3s all;
  cursor: pointer;
}
.tooltip {
  display: block !important;
  z-index: 10000;
  max-width: 768px;
}

.tooltip .tooltip-inner {
  background: #00A1E5;
  color: white;
  /* border-radius: 16px; */
  /* padding: 5px 10px 4px; */
  border-radius: 1rem;
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%);
  padding: 20px;
  text-align: left;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #00A1E5;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: #005F9E;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>
