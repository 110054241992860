import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/functions'

const firebaseConfig = { 
    apiKey: "AIzaSyAA8ZWR9bnEIHTJoZOGroUT191UeOTCd3M",
    authDomain: "cszakaznici.firebaseapp.com",
    projectId: "cszakaznici",
    storageBucket: "cszakaznici.appspot.com",
    messagingSenderId: "347975724321",
    appId: "1:347975724321:web:d4bb3a61a01255b69ca260",
    measurementId: "G-RL61PX9BE2"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const db = firebase.firestore()
export const functions = firebase.functions()