<template>
  <div class="about" style="padding-left: 33%; padding-right: 33%; padding-top: 10% ">
    <b-field>
        <b-select expanded placeholder="Select..." v-model="selected" @input="q=0">
          <option v-for="(option, index) in type" :value="option" :key="index">{{ option }}</option>
        </b-select>
    </b-field>

    <div v-if="selected == '1'">
      <b-field label="Quantity (increments of 5)">
        <b-numberinput v-model="q" step=5 min=0></b-numberinput>
      </b-field>
    </div>

    <div v-if="selected == '2'">
      <b-field label="Quantity">
        <b-numberinput v-model="q" step=1 min=0></b-numberinput>
      </b-field>
    </div>
  </div>
</template>

<script>
export default{
  data: function(){
    return{
      type: ["1", "2"],
      selected: null,
      q: 0,
    }
  }
}
</script>

